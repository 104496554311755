import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import Button from '@mui/material/Button/Button';
import SVGImage from 'components/atoms/SVGImage/SVGImage';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import Section from 'components/organisms/Section/Section';
import CardPricingStandard from 'components/organisms/CardPricingStandard/CardPricingStandard';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(),
    },
  },
  sectionContainer: {
    backgroundColor: theme.palette.primary.dark,
    paddingBottom: theme.spacing(20),
    borderRadius: '0 0 100% 0',
  },
  textWhite: {
    color: 'white',
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  toggleContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(0, 2),
  },
  toggleButtonGroup: {
    background: 'transparent',
  },
  toggleButton: {
    background: 'transparent',
    border: '1px solid white',
    padding: theme.spacing(1, 5),
  },
  toggleButtonActive: {
    backgroundColor: 'white !important',
  },
  toggleTitle: {
    textTransform: 'capitalize',
  },
  toggleTitleActive: {
    color: theme.palette.primary.main,
  },
  pricingContainer: {
    position: 'relative',
    marginTop: theme.spacing(-20),
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  cardPricing: {
    '& .countup-number__count-wrapper': {
      textAlign: 'left',
      marginBottom: 0,
      fontWeight: 'bold',
    },
  },
}));

const Main = (props) => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [pricingOption, setPricingOption] = useState([]);

  const handleClick = (event, newPricingOption) => {
    setPricingOption(newPricingOption);
  };

  const openCheckout = (event, licenseType) => {
    window.Paddle.Setup({ vendor: process.env.PADDLE_VENDOR_KEY | 0 });
    window.Paddle.Checkout.open({ product: licenseType });
  };

  React.useEffect(() => {
    function getCosts(prices) {
      setPricingOption(prices.price);
    }
    if (window.Paddle) {
      window.Paddle.Product.Prices(
        process.env.PADDLE_PRODUCT_PROFESSIONAL_KEY,
        getCosts,
      );
    } else setPricingOption([]);
  }, [pricingOption]);

  const buttonSwitch = (param) => {
    switch (param) {
      case Number(process.env.PADDLE_PRODUCT_PROFESSIONAL_KEY):
        return (
          <Button
            color="primary"
            variant={'contained'}
            fullWidth
            size="large"
            onClick={(e) =>
              openCheckout(
                e,
                Number(process.env.PADDLE_PRODUCT_PROFESSIONAL_KEY),
              )
            }
          >
            Click here to Buy Now
          </Button>
        );
        break;
      // case 1234:
      //   return (
      //     <Button
      //       color="primary"
      //       variant={'contained'}
      //       fullWidth
      //       size="large"
      //       href="/get-trial"
      //     >
      //       Click Here for Free Trial
      //     </Button>
      //   );
      //   break;
      default:
        return (
          <Button
            color="primary"
            variant={'contained'}
            fullWidth
            size="large"
            href="/contact-us"
          >
            Contact Us
          </Button>
        );
    }
  };

  const renderSwitch = (param) => {
    var ProfKey = Number(process.env.PADDLE_PRODUCT_PROFESSIONAL_KEY);
    switch (param) {
      case ProfKey:
        return renderPrice(pricingOption);
        break;
      case 1234:
        return (
          <Fragment>
            <Typography variant="h3" color="textPrimary">
              FREE
            </Typography>
          </Fragment>
        );
        break;
      default:
        return (
          <Fragment>
            <Typography variant="h3" color="textPrimary">
              £7,200.00
            </Typography>
            <Typography variant="h5" color="textPrimary">
              £6,000.00 +VAT
            </Typography>
          </Fragment>
        );
    }
  };
  return (
    <div className={className} {...rest}>
      <div className={classes.sectionContainer}>
        <Section narrow className={classes.pagePaddingTop} align="center">
          <SectionHeader
            title="Commercial Licensing"
            subtitle="We are founded by a leading academic and researcher in the fields of digital outcrop modelling and subsurface geological characterisation. "
            //subtitle="Due to holiday VRGeoscience will not be able to process new license requests until 24/08/2022. Please check back then to place an order."
            titleProps={{
              className: clsx(classes.textWhite, classes.fontWeightBold),
              variant: 'h2',
            }}
            subtitleProps={{
              className: classes.textWhite,
            }}
            data-aos="fade-up"
          />
          <Button
            variant="contained"
            color="secondary"
            component="a"
            target="blank"
            href="/pricing-academic"
            className={classes.listItemButton}
          >
            Click Here for Academic Licensing
          </Button>
        </Section>
      </div>
      <div className={classes.pricingContainer}>
        <div>
          <Section className={classes.sectionNoPaddingTop}>
            <Grid container spacing={isMd ? 4 : 2}>
              {data.map((item, index) => (
                <Grid item xs={12} md={4} data-aos="fade-up" key={index}>
                  <CardPricingStandard
                    variant="outlined"
                    withShadow={item.isHighlighted ? true : false}
                    title={item.title}
                    liftUp
                    subtitle={item.subtitle}
                    priceComponent={<div>{renderSwitch(item.productId)}</div>}
                    features={item.features}
                    featureCheckComponent={
                      <SVGImage
                        src="/images/svg/check-circle.svg"
                        fontIconColor={theme.palette.primary.main}
                      />
                    }
                    cta={buttonSwitch(item.productId)}
                    disclaimer={item.disclaimer}
                    className={classes.cardPricing}
                  />
                </Grid>
              ))}
            </Grid>
          </Section>
        </div>
      </div>
    </div>
  );
};
Main.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Main;

function renderPrice(pricingOption1) {
  return (
    <div>
      <Typography variant="h3" color="textPrimary">
        {pricingOption1.gross}
      </Typography>
      <Typography variant="h5" color="textPrimary">
        ({pricingOption1.net}+VAT)
      </Typography>
    </div>
  );
}
