export const pricings = [
  {
    title: '30 day trial license',
    subtitle: 'No obligation free trial',
    monthly: 49,
    annual: 1000,
    licenseType: 5,
    productId: 1234,
    priceSuffix: ' / MO',
    features: [
     'Trial puposes only',
     '30 day Duration',
     'Node Locked',
      'All base modules',
'e-mail support',
    ],
    disclaimer: '',
    isHighlighted: true,
  },
  {
    title: '1 Year Professional License',
    subtitle: 'For indviduals and companies < 5 people',
    monthly: 49,
    annual: 1000,
    licenseType: 3,
    productId: 648504,
    priceSuffix: ' / MO',
    features: [
      'Node locked licensing',
      'cloud floating licensing',
      'Regular updates',
      'e-mail & video support ',
      'Online documentation',
    ],
    disclaimer: 'Secure checkout by www.paddle.com',
    isHighlighted: true,
  },
  {
    title: '1 Year Enterprise License',
    subtitle: 'Unleash the full power of VRGS',
    monthly: 'POA',
    annual: 'POA',
    licenseType: 4,
    productId: 0,
    priceSuffix: ' / MO',
    features: [
      'Standard License benefits+',
      'On-prem Floating License',
      'Video-call support',
      'Includes all current modules',
      'Priority influence on roadmap',
    ],
    disclaimer: '',
    isHighlighted: true,
  },
];

export const faq = [
  {
    id: 'faq-1',
    title: 'Can I get an online demo?',
    subtitle: '',
    text:
      'Contact the support e-mail and we will be happy to provide and online demo.',
    link: '',
  },
  {
    id: 'faq-2',
    title: 'Can I buy with a credit card or purchase order?',
    subtitle: '',
    text: 'We can support either. Contact us for more information.',
    link: '',
  },
  {
    id: 'faq-3',
    title: 'What happens when my license expires?',
    subtitle: '',
    text:
      'VRGS will switch into viewer only mode, so you can still view your models and data. Renew your license to re-activate the full version.',
    link: '',
  },
  {
    id: 'faq-4',
    title: 'I am unable to use the MSIX approach for installation. What can I do?',
    subtitle: '',
    text:
      'If your admin will not allow side-loading of apps or the MSIX installer then we can provide you with a standard MSI. Just contact us for the link.',
    link: '',
  },
  {
    id: 'faq-5',
    title: 'Can you add features on request?',
    subtitle: '',
    text:
      'Requests for features are always welcome as VRGS development is driven by user requirements. This does not necessarily mean they get added quickly though. If you need a bespoke feature quickly then that is an additional service we can supply.',
    link: '',
  },
];
