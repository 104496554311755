/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import Pricing from 'views/PricingCommercial';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

import SEO from '../src/components/SEO';

export const Head = () => (
  <>
    <script src="https://cdn.paddle.com/paddle/paddle.js"></script>
    <SEO title="Commercial Pricing for VRGS" />;
  </>
);

const PricingPage = () => {
  return <WithLayout component={Pricing} layout={Main} />;
};

export default PricingPage;
